import { Amplify } from 'aws-amplify';
import { Config } from '../../config';
import getAwsConfig from './aws-exports';

export default (config: Config) => {
    const awsConfig = getAwsConfig(config);
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: awsConfig.aws_user_pools_id,
                userPoolClientId: awsConfig.aws_user_pools_web_client_id,
                identityPoolId: awsConfig.aws_cognito_identity_pool_id,
                loginWith: {
                    oauth: awsConfig.oauth,
                },
            },

        },
    });
};
