import React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';

import { ReferenceManyToManyField } from '@react-admin/ra-relationships';

import CustomEditTitle from '../CustomTitle/CustomEditTitle';
import { ComponentForm } from './ComponentForm';

export const List = (): React.ReactElement => (
    <MUI.Box display={'flex'} flexDirection={'row'}>
        <MUI.Box flex={1}>
            <RA.List
                perPage={1000}
                sort={{
                    field: 'component_name',
                    order: 'ASC',
                }}
                hasCreate={false}
            >
                <RA.Datagrid bulkActionButtons={<RA.BulkExportButton />}>
                    <RA.TextField source="component_name" label="Name" />
                    <ReferenceManyToManyField
                        label="Tiles"
                        reference="tiles"
                        through="components_tiles"
                        perPage={1000}
                        using="component_id,tile_id"
                    >
                        <RA.SingleFieldList>
                            <RA.ChipField source="tilename" />
                        </RA.SingleFieldList>
                    </ReferenceManyToManyField>
                    <RA.EditButton />
                </RA.Datagrid>
            </RA.List>
        </MUI.Box>
    </MUI.Box>
);

export const Edit = (): React.ReactElement => (
    <RA.Edit
        title={<CustomEditTitle name={'component'} field={'component_name'} />}
    >
        <ComponentForm />
    </RA.Edit>
);

// export const Create = (): React.ReactElement => (
//     <RA.Create>
//         <ComponentForm />
//     </RA.Create>
// );
