import * as React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';
import { ReferenceManyToManyField } from '@react-admin/ra-relationships';

import CustomEditTitle from '../CustomTitle/CustomEditTitle';

import { BulkActionButtons } from '../CustomButtons/BulkActionButtons';
import { UsersForm } from './UsersForm';

const ListPagination = (props: RA.PaginationProps) => (
    <RA.Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const usersListFilters = [
    <RA.SearchInput source={'name'} placeholder="Name" alwaysOn />,
    <RA.SearchInput source={'pmid'} placeholder="Pmid" alwaysOn />,
];

export const List = (): React.ReactElement => (
    <MUI.Box>
        <MUI.Box flex={1}>
            <RA.List
                perPage={100}
                pagination={<ListPagination />}
                filters={usersListFilters}
            >
                <RA.Datagrid bulkActionButtons={<BulkActionButtons />}>
                    <RA.TextField source="name" label="Name" />
                    <RA.TextField source="email" label="Email" />
                    <RA.TextField source="pmid" label="PMID" />
                    <ReferenceManyToManyField
                        label="Configs"
                        reference="app_config_overrides"
                        through="app_config_users_overrides"
                        using="pmid,override_id"
                    >
                        <RA.SingleFieldList>
                            <RA.ChipField source="slug" />
                        </RA.SingleFieldList>
                    </ReferenceManyToManyField>
                    <RA.EditButton />
                </RA.Datagrid>
            </RA.List>
        </MUI.Box>
    </MUI.Box>
);

const CreateToolBar = (props: RA.ToolbarProps): React.ReactElement => (
    <RA.Toolbar {...props}>
        <RA.SaveButton type="button" alwaysEnable />
    </RA.Toolbar>
);

export const Create = (): React.ReactElement => (
    <RA.Create redirect={'list'}>
        <UsersForm toolbar={<CreateToolBar />} />
    </RA.Create>
);

const EditToolBar = (): React.ReactElement => (
    <RA.Toolbar sx={{ gap: 4 }}>
        <RA.SaveButton type="button" alwaysEnable />
        <RA.DeleteWithConfirmButton />
    </RA.Toolbar>
);

export const Edit = (): React.ReactElement => (
    <RA.Edit title={<CustomEditTitle name={'user'} field={'pmid'} />}>
        <UsersForm toolbar={<EditToolBar />} />
    </RA.Edit>
);
