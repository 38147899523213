// import for MUI Component
import * as MUI from '@mui/material';

// import for icons
// eslint-disable-next-line import/no-extraneous-dependencies
import { TopicOutlined, ArticleOutlined } from '@mui/icons-material';

// import for page elements
import ComponentsList from './Component/ComponentsList';
import Tabs from '../CustomComponents/Tabs';
import { List as TilesList } from './Tile/TilesV2';

const Dashboard = () => (
    <MUI.Box width="100%">
        <Tabs
            icons={[<TopicOutlined />, <ArticleOutlined />]}
            tabs={['Components', 'Tiles']}
        >
            <ComponentsList/>
            <TilesList resource="tiles"/>
        </Tabs>
    </MUI.Box>
);

export default Dashboard;
