import { defaultTheme } from 'react-admin';
import { createTheme } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import merge from 'lodash/merge';

export const lightTheme = createTheme(
    merge({}, defaultTheme, {
        palette: {
            mode: 'light',
            primary: {
                main: '#0068C4',
            },
            secondary: {
                main: '#0068C4',
                light: '#AF0A34',
                dark: '#AF0A34',
                contrastText: '#FFFFFF',
            },
            text: {
                primary: '#000000',
                secondary: '#000000',
            },
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        '& .RaDatagrid-headerCell': {
                            borderBottom: 'none',
                        },
                        '& .RaDatagrid-rowCell': {
                            borderBottom: 'none',
                        },
                    },
                },
            },
            RaMenu: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#ffffff',
                        height: '90vh',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        borderBottom: '20px solid #0068B4',
                        borderTop: '2px solid #0068B4',
                    },
                },
            },
        },
        activeRow: {
        },
    }),
);

export const darkTheme = createTheme(
    merge({}, defaultTheme, {
        palette: {
            mode: 'dark',
            primary: {
                main: '#FFFFFF',
                contrastText: '#212121',
            },
            secondary: {
                light: '#212121',
                main: '#212121',
                dark: '#212121',
                contrastText: '#212121',
            },
            background: {
                paper: '#2F2F2F',
                default: '#2F2F2F',
            },
            text: {
                primary: '#FFFFFF',
                secondary: '#FFFFFF',
            },
        },
        // Your theme goes here
        // Write the following code to have an orange app bar. We'll explain it later in this article.
    }),
);
