import * as RA from 'react-admin';
import * as MUI from '@mui/material';

export const TilesFormHeader = () => (
    <>
        <MUI.Box paddingY={3} display="flex" flexDirection="column">
            <MUI.Box width="100%" display="flex" flexDirection="row" alignItems="center">
                <RA.TextInput
                    label="Tile Title"
                    source="slug"
                    validate={RA.required()}
                    sx={{ width: '100%', marginRight: '1em' }}
                    helperText={false}
                    fullWidth
                />
                <RA.BooleanInput label="Filter Status" source="published" helperText={false} />
            </MUI.Box>
            <RA.SelectInput
                source="tile_type"
                choices={[
                    { id: 'standard', name: 'Standard Tile' },
                    { id: 'service', name: 'Service Tile' },
                ]}
                defaultValue={'standard'}
                validate={RA.required()}
                helperText={false}
            />
        </MUI.Box>
    </>
);
