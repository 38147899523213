// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthProvider } from 'ra-core';
import {
    fetchAuthSession, signIn, signInWithRedirect, signOut,
} from '@aws-amplify/auth';

const authProvider: AuthProvider = {
    checkAuth: async () => {
        try {
            const session = await fetchAuthSession();
            if (!(session && session.tokens && session.tokens?.accessToken)) {
                throw new Error('User not authenticated');
            }
        } catch (e) {
            // Clean all localStorage keys relative to cognito connexion
            Object.keys(localStorage)
                .filter(
                    (key) => key.startsWith('CognitoIdentityServiceProvider')
                        || key.startsWith('amplify-'),
                )
                .forEach((key) => localStorage.removeItem(key));

            throw e;
        }
    },
    checkError: () => Promise.resolve(),
    getPermissions: () => Promise.resolve({}),
    login: async (params: any) => {
        // eslint-disable-next-line no-underscore-dangle
        if (params._withAzure) {
            return signInWithRedirect();
        }

        const { username, password } = params;
        return signIn({ username, password });
    },
    logout: () => signOut(),
};

export default authProvider;
