import React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';
import { ReferenceManyToManyInput } from '@react-admin/ra-relationships';

export const UsersForm = ({ toolbar: Toolbar }: any): React.ReactElement => (
    <MUI.Box paddingY={3} display="flex" flexDirection="column">
        <RA.SimpleForm
            toolbar={Toolbar}
            mode={'onBlur'}
            warnWhenUnsavedChanges
        >
            <MUI.Box width="100%" display="flex" flexDirection="row" alignItems="center">
                <RA.TextInput
                    source="pmid"
                    validate={[RA.required(), RA.maxLength(8)]}
                    helperText={false}
                    sx={{
                        width: '100%',
                        marginRight: '1em',
                    }}
                />
                <RA.TextInput
                    source="name"
                    helperText={false}
                    fullWidth
                />
            </MUI.Box>
            <MUI.Box width="100%" display="flex" flexDirection="row" alignItems="center">
                <RA.TextInput
                    source="email"
                    helperText={false}
                    validate={RA.email()}
                    fullWidth
                    sx={{
                        width: '100%',
                        marginRight: '1em',
                    }}
                />
                <ReferenceManyToManyInput
                    source="pmid"
                    reference="app_config_overrides"
                    through="app_config_users_overrides"
                    using="pmid,override_id"
                >
                    <RA.SelectArrayInput
                        label="Config"
                        // Validation must be set on this component
                        validate={RA.required()}
                        optionText="slug"
                        helperText={false}
                        fullWidth
                    />
                </ReferenceManyToManyInput>
            </MUI.Box>
        </RA.SimpleForm>
    </MUI.Box>

);
