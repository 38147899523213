import React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';

export const TileLocaleSelector = (props: { onChange: (locale: string) => void }): React.ReactElement => {
    const {
        locales,
        selectLocale,
        selectedLocale,
    } = RA.useTranslatableContext();
    const handleChange = (_e: any, locale: string) => {
        selectLocale(locale);
        props.onChange(locale);
    };

    return (
        <RA.Toolbar sx={{
            '&.RaToolbar-desktopToolbar': {
                padding: 0,
                justifyContent: 'center',
                minHeight: 0,
            },
        }} variant={'dense'}>
            <MUI.Tabs
                value={selectedLocale}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                sx={{ minHeight: 0 }}
            >
                {locales.map((locale) => (
                    <RA.TranslatableInputsTab
                        key={locale}
                        value={locale}
                        locale={locale}
                        groupKey={'locales'}
                    />
                ))}
            </MUI.Tabs>
        </RA.Toolbar>
    );
};
