import React from 'react';
import * as RA from 'react-admin';
import { SaveButton, Toolbar } from 'react-admin';
import { DualListInput, ReferenceManyToManyInput } from '@react-admin/ra-relationships';

const PostCreateToolbar = (props: any) => {
    const { ...otherProps } = props;

    return (
        <Toolbar {...otherProps}>
            <SaveButton
                type="button"
            />
        </Toolbar>
    );
};

export const ComponentForm = (): React.ReactElement => (
    <RA.SimpleForm toolbar={<PostCreateToolbar />} warnWhenUnsavedChanges>
        <RA.TextInput
            source="component_name"
            validate={RA.required()}
            disabled
        />
        <ReferenceManyToManyInput
            source="tile_ids"
            reference="tiles"
            through="components_tiles"
            using="component_id,tile_id"
            sortChoices={{
                field: 'priority',
                order: 'ASC',
            }}
            perPage={1000}
            perPageChoices={1000}
        >
            <DualListInput
                availableItemsLabel="Available Tiles"
                selectedItemsLabel="Selected Tiles"
                label="Tiles"
                validate={RA.required()}
                optionText="tilename"
            />
        </ReferenceManyToManyInput>
    </RA.SimpleForm>
);
