import * as RA from 'react-admin';
import * as MUI from '@mui/material';

export const TilesFormHeader = () => (
    <MUI.Box paddingY={3} display="flex" flexDirection="column">
        <MUI.Box width="100%" display="flex" flexDirection="row" alignItems="center">
            <RA.TextInput
                source="tilename"
                validate={RA.required()}
                sx={{
                    width: '100%',
                    marginRight: '1em',
                }}
                helperText={false}
                fullWidth
            />
            <RA.BooleanInput source="published" helperText={false} />
        </MUI.Box>
        <RA.NumberInput
            source="priority"
            min={0}
            step={1}
            validate={RA.required()}
            helperText={false}
        />

        <RA.SelectInput
            source="type"
            choices={[
                {
                    id: 'standard',
                    name: 'Standard Tile',
                },
                {
                    id: 'service',
                    name: 'Service Tile',
                },
            ]}
            defaultValue={'standard'}
            validate={RA.required()}
            helperText={false}
        />
    </MUI.Box>

);
