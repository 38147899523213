import * as RA from 'react-admin';
import * as MUI from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { locales } from '../../../locales';
import { LinkType } from '../../../types';

import { SubtitleValidation, TitleValidation } from '../../../formValidations/validateTitle';
import { TileLocaleSelector } from '../../FormComponents/TileLocaleSelector';
import { urlRegex } from '../../../constants';

const LinkTypes = [
    {
        id: LinkType.browser,
        name: 'BROWSER',
    },
    {
        id: LinkType.deeplink,
        name: 'DEEPLINK',
    },
    {
        id: LinkType.webview,
        name: 'WEBVIEW',
    },
];

const StandardContentPanel = () => {
    const formContext = useFormContext();
    const content = formContext.getValues('content');
    const {
        selectLocale,
        selectedLocale,
    } = RA.useTranslatableContext();
    const handleLocaleChange = (locale: string) => selectLocale(locale);

    return (
        <MUI.Box>
            {/* Text */}
            <MUI.Typography variant="h6" mt={3}>Text Localization</MUI.Typography>
            <RA.TranslatableInputs
                locales={locales}
                defaultLocale={selectedLocale}
                selector={
                    <TileLocaleSelector onChange={handleLocaleChange} />
                }
            >
                <RA.TextInput
                    source="content.title"
                    label="Title"
                    validate={TitleValidation}
                    helperText={false}
                    fullWidth
                />
                <RA.TextInput
                    source="content.subtitle"
                    label="Subtitle"
                    validate={SubtitleValidation}
                    disabled={!content?.title[selectedLocale]}
                    helperText={!content?.title[selectedLocale] ? 'A title must be filled in before choosing a subtitle' : false}
                    fullWidth
                />
                <RA.TextInput source="content.incentive" label="Incentive" fullWidth helperText={false} />
                <RA.TextInput source="content.extended_text" label="Extended text" fullWidth helperText={false} />
            </RA.TranslatableInputs>

            {/* Images */}
            <MUI.Typography variant="h6" mt={3}>Images</MUI.Typography>
            <MUI.Box sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <RA.TextInput
                    source="content.picture"
                    label="Picture"
                    validate={[
                        RA.required(),
                        RA.regex(
                            urlRegex,
                            'Picture must be a valid url',
                        ),
                    ]}
                    helperText={false}
                />
                <RA.TextInput
                    source="content.picto"
                    label="Picto"
                    validate={[
                        RA.regex(
                            urlRegex,
                            'Picto must be a valid url',
                        ),
                    ]}
                    helperText={false}
                />
            </MUI.Box>

            {/* Links */}
            <MUI.Typography variant="h6" mt={3}>Link</MUI.Typography>
            <MUI.Box sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <RA.TextInput
                    source="content.link"
                    label="Link"
                    validate={[
                        RA.required(),
                        RA.regex(
                            urlRegex,
                            'Link must be a valid url',
                        ),
                    ]}
                    helperText={'To vary the language, country and platform insert {lang}, {country} and/or {platform}'}
                    fullWidth
                />
                <RA.SelectInput
                    source="content.link_type"
                    label="Link type"
                    choices={LinkTypes}
                    validate={RA.required()}
                />
            </MUI.Box>
        </MUI.Box>

    );
};

export default StandardContentPanel;
