import { locales } from '../locales';
import { TileValidation } from '../types';

type TitleValidations = (
    value: TileValidation,
    allValues: TileValidation
) => string | undefined;

export const TitleValidation: TitleValidations = (
    values,
    allValues,
) => {
    const { content } = allValues;
    const {
        title,
        subtitle,
    } = content;
    const localesErrored: string[] = [];

    if (Object.values(title)
        .every((x) => x === null || x === '')) {
        return 'Title is required for at least one language';
    }

    if (title && subtitle) {
        locales.forEach((locale) => {
            if (!title[locale] && subtitle[locale] && subtitle[locale].length >= 1) {
                localesErrored.push(locale);
            }
        });
    }

    if (localesErrored.length >= 1) {
        return `Title is required when subtitle is filled (${localesErrored})`;
    }

    // React Admin doc specifies that you should return undefined when you have no error
    // See here : https://marmelab.com/react-admin/CreateEdit.html#per-input-validation-custom-function-validator
    return undefined;
};

export const SubtitleValidation: TitleValidations = (
    values,
    allValues,
) => {
    const { content } = allValues;
    const {
        title,
        subtitle,
    } = content;
    const localesErrored: string[] = [];

    if (title && subtitle) {
        locales.forEach((locale) => {
            if (title[locale] && title[locale].length >= 1 && !subtitle[locale]) {
                localesErrored.push(locale);
            }
        });
    }

    if (localesErrored.length >= 1) {
        return `Subtitle is required when a title is filled (${localesErrored})`;
    }

    return undefined;
};
