import * as React from 'react';
import { Box } from '@mui/material';
import { Menu } from 'react-admin';

// eslint-disable-next-line import/no-extraneous-dependencies
import { ViewCarouselRounded, PhonelinkSetupRounded } from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomMenu: React.FC<any> = ({ onMenuClick, logout }) => (
    <Box
        display={'flex'}
        flexDirection={'column'}
        flex={1}
        padding={'4px'}
        mt={1}
    >
        <Box flex={2}>
            <Menu>
                <Menu.Item
                    to="/V2/dashboard"
                    primaryText="ADMIN"
                    style={{ color: 'grey' }}
                    leftIcon={<PhonelinkSetupRounded style={{ color: '#0068C4' }} />} />
                <Menu.Item
                    to="/V2/tiles"
                    primaryText="Tiles"
                    style={{ color: 'grey' }}
                    leftIcon={<ViewCarouselRounded style={{ color: '#0068C4' }} />} />
            </Menu>
        </Box>

    </Box>
);

export default CustomMenu;
