import * as RA from 'react-admin';
import CustomAppBar from './CustomAppBar';
import CustomMenu from './CustomMenu';

// eslint-disable-next-line no-undef
const CustomLayout = (props: any): JSX.Element => (
    <RA.Layout {...props} menu={CustomMenu} appBar={CustomAppBar} />
);

export default CustomLayout;
