import * as RA from 'react-admin';

import CustomMenuV2 from './CustomMenuV2';
import CustomAppBar from './CustomAppBarV2';

// eslint-disable-next-line no-undef
const CustomLayout = (props: any): JSX.Element => (
    <RA.Layout {...props} menu={CustomMenuV2} appBar={CustomAppBar}/>
);

export default CustomLayout;
