import * as RA from 'react-admin';
import { ExpirationTime, Tiering, Tile } from '../types';

type CardExpirationValidation = (
    value: string,
    allValues: Partial<Tile>
) => string | undefined;

const cardExpirationValidation: CardExpirationValidation = (
    value,
    allValues,
) => {
    const {
        tiering,
        user_card_expiration,
    } = allValues;
    if (tiering && tiering.includes(Tiering.classic) && user_card_expiration !== ExpirationTime.anytime) {
        return 'Classic Cards don\'t expire.';
    }

    // React Admin doc specifies that you should return undefined when you have no error
    // See here : https://marmelab.com/react-admin/CreateEdit.html#per-input-validation-custom-function-validator
    return undefined;
};

const validateCardExpiration = [RA.required(), cardExpirationValidation];

export default validateCardExpiration;
