/* eslint-disable import/extensions */
import * as RA from 'react-admin';
import * as MUI from '@mui/material';
import {

    ExpirationTime,
    PlatformType,
    RuleType,
    Tiering,
    UserType,

} from '../../../../types';

import { countryListAllIsoData } from '../../../../locales';
import validateCardExpiration from '../../../../formValidations/validateCardExpiration';

import AutocompleteCountriesInput from '../../../CustomInputs/AutocompleteCountriesInput';
import getOnChangeFunctions
// { isUserProspectFromFormData }
    from './onChangeFunctions';

const SettingsPanel = () => {
    // const record = RA.useRecordContext();
    // const tileFilterId = record?.tileFilter?.id;
    const onChange = getOnChangeFunctions();
    // const { data: tileFiltersData, isLoading: tileFiltersLoading, error: tileFiltersError } = RA.useGetOne('tiles-filter', { id: tileFilterId }, { enabled: !!tileFilterId });
    // console.log('new record :', record);
    return (
        <MUI.Box width="100%">
            {
                /**
                 * ********
                 * LOCATION
                 * ********
                 */
            }
            <MUI.Typography variant="h6" mt={3}>Location</MUI.Typography>
            <AutocompleteCountriesInput
                source="countries"
                choices={countryListAllIsoData}
                helperText={false}
                translateChoice={false}
            />
            <RA.SelectInput
                source="countriesRule"
                choices={[
                    { id: RuleType.include, name: 'Inclusion list' },
                    { id: RuleType.exclude, name: 'Exclusion list' },
                    { id: RuleType.none, name: 'No rule' },
                ]}
                defaultValue={RuleType.none}
                helperText={false}
                fullWidth
            />

            {
                /**
                 * *********
                 * PROFILING
                 * *********
                 */
            }
            <MUI.Typography variant="h6" mt={3}>Profiling</MUI.Typography>
            <RA.SelectInput
                source="platforms"
                choices={[
                    { id: PlatformType.ios, name: 'iOS' },
                    { id: PlatformType.android, name: 'Android' },
                    { id: PlatformType.any, name: 'iOS & Android' },
                ]}
                validate={RA.required()}
                helperText={false}
                fullWidth
            />

            <MUI.Box display="flex" flexDirection={'row'} alignItems={'flex-start'} gap={1}>
                <RA.SelectInput
                    source="userType"
                    choices={[
                        { id: UserType.everyone, name: 'Everyone' },
                        {
                            id: UserType.accorPlus,
                            name: 'Accor Plus (D1, D2, ...)',
                        },
                        {
                            id: UserType.memberAllNoAccorPlus,
                            name: 'Membres ALL sans les Accor Plus (A1, A2, ...)',
                        },
                        {
                            id: UserType.everyMemberAll,
                            name: 'Membres ALL (A* & D* & P*)',
                        },
                        {
                            id: UserType.prospect,
                            name: 'Prospect (pas de carte ou pas connecté)',
                        },
                    ]}
                    validate={RA.required()}
                    onChange={onChange.userType}
                    helperText={false}
                    fullWidth
                />
                <RA.FormDataConsumer>
                    {({ formData, ...rest }) => [UserType.memberAllNoAccorPlus, UserType.everyMemberAll, UserType.accorPlus].includes(
                        formData.user_type,
                    ) && (
                        <RA.AutocompleteArrayInput
                            source="tiering"
                            choices={[
                                { id: Tiering.classic, name: 'classic' },
                                { id: Tiering.silver, name: 'silver' },
                                { id: Tiering.gold, name: 'gold' },
                                { id: Tiering.platinum, name: 'platinum' },
                                { id: Tiering.diamond, name: 'diamond' },
                                { id: Tiering.limitless, name: 'limitless' },
                            ]}
                            helperText="Leave empty for every tiering"
                            fullWidth
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
                <RA.FormDataConsumer>
                    {({ formData, ...rest }) => [UserType.memberAllNoAccorPlus, UserType.everyMemberAll, UserType.accorPlus].includes(
                        formData.user_type,
                    ) && (
                        <RA.SelectInput
                            source="userCardExpiration"
                            choices={[
                                { id: ExpirationTime.anytime, name: 'Anytime' },
                                { id: ExpirationTime.oneMonth, name: '1 Month' },
                                {
                                    id: ExpirationTime.twoMonths,
                                    name: '2 Months',
                                },
                                {
                                    id: ExpirationTime.threeMonths,
                                    name: '3 Months',
                                },
                            ]}
                            validate={validateCardExpiration}
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
            </MUI.Box>

            {
                /**
                 * *******
                 * TRACKING
                 * *******
                 */
            }
            <MUI.Typography variant="h6" mt={3}>Tracking</MUI.Typography>
            <RA.TextInput
                source="trackingLabel"
                fullWidth
            />
        </MUI.Box >
    );
};

export default SettingsPanel;
