import * as MUI from '@mui/material';

const StoryScreenCreateModal = ({
    children,
    open,
    handleClose,
}) => (
    <MUI.Modal
        open={open === 'new'}
        onClose={handleClose}
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <>
            {children}
        </>
    </MUI.Modal>
);

export default StoryScreenCreateModal;
