import {
    List, Datagrid, TextField, EditButton,
} from 'react-admin';

const SectionsList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="component.name" label="TITLE" />
            <TextField source="name" label="Name" />
            <TextField source="description" label="Description" />
            <EditButton />
        </Datagrid>
    </List>
);

export default SectionsList;
