import * as RA from 'react-admin';
import { useRefresh } from 'react-admin';
import * as MUI from '@mui/material';
import { useFormContext } from 'react-hook-form';

// eslint-disable-next-line import/no-extraneous-dependencies
import DeleteIcon from '@mui/icons-material/Delete';

const StoryScreenDeleteAction = () => {
    const record = RA.useRecordContext();
    const {
        getValues,
        setValue,
    } = useFormContext();
    const refresh = useRefresh();

    const storyScreenValues = getValues('content.story_screens');

    const handlePressDelete = async () => {
        const itemToIndex = storyScreenValues.find((story) => story.id === record.id);
        const indexOfItem = storyScreenValues.indexOf(itemToIndex);

        await storyScreenValues.splice(indexOfItem, 1);

        setValue('content.story_screens', storyScreenValues);
        refresh();
    };

    return (
        <MUI.Button
            variant="text"
            color="error"
            onClick={handlePressDelete}
            startIcon={<DeleteIcon />}
        >
            Delete
        </MUI.Button>
    );
};

export default StoryScreenDeleteAction;
