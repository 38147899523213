import * as React from 'react';
import * as RA from 'react-admin';

const CustomEditTitle = ({
    name,
    field,
}) => {
    const record = RA.useRecordContext();
    return (
        <span>Edit {name} <strong>{record ? `${record[`${field}`]}` : ''}</strong></span>
    );
};

export default CustomEditTitle;
