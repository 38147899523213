import React, { useState } from 'react';
import {
    Box, Button, Table, TableBody, TableCell, TableRow, Typography,
} from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    ExpandMore, ExpandLess, VisibilityOutlined, VisibilityOffOutlined,
} from '@mui/icons-material';
import { EditButton } from 'react-admin';
// import CustomtextField from '../../CustomComponents/CustomtextField';

const GroupedList = ({
    // componentId,
    componentTile, sectionName, items,
}) => {
    const [showTiles, setShowTiles] = useState(false);
    const totalItems = items.length;
    // console.log(componentId);
    const toggleTiles = () => {
        setShowTiles(() => !showTiles);
    };

    return (
        <Box
            bgcolor={showTiles ? '#dedede' : '#fafafa'}
            p={2}
            borderRadius="6px"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
            mb={1}
            mt={1}
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems={'center'}
            >
                <Box flex={1} display="flex" alignItems="center" ml={2}>
                    <Typography variant="body2">
                            Title :
                    </Typography>
                    <Box fontWeight={'bold'} color="#0068C4" ml={1}>
                        {componentTile}
                    </Box>
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection={'column'}>
                    <Typography variant="body1" >
                        Section
                    </Typography>
                    <Box fontWeight={'bold'} color={'#0068C4'}>
                        {sectionName}
                    </Box>
                </Box>
                <Box
                    flex={1}
                    display="flex"
                    flexDirection={'row'}
                    alignItems={'flex-start'}>
                    <Typography variant="body1">
                        Total Tiles:
                    </Typography>
                    <Box color={'#0068C4'} ml={1} fontWeight={'bold'}>
                        {totalItems}
                    </Box>
                </Box>
                <Button onClick={toggleTiles} style={{ marginLeft: '10px', marginRight: '10px' }}>
                    {showTiles ? (
                        <Box display="flex" alignItems="center">
                            <ExpandLess /> Hide Details
                        </Box>
                    ) : (
                        <Box display="flex" alignItems="center">
                            <ExpandMore /> Show Details
                        </Box>
                    )}
                </Button>
            </Box>
            {showTiles && (
            // Box for tile details
                <Box>
                    <Table>
                        <TableBody>
                            {items.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell colSpan={3} sx={{ borderBottom: 'none', padding: 1 }}>
                                        <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="center"
                                            p={2}
                                            borderRadius="6px"
                                            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
                                            bgcolor="background.paper"
                                        >
                                            <Box display="flex" paddingLeft={2} paddingRight={2}>
                                                {item.tile.published ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                            </Box>

                                            <Box flex={1} display={'flex'} flexDirection={'row'} paddingLeft={2} alignItems={'center'}>
                                                <Typography variant="body1" color="textSecondary">
                                                        Title:
                                                </Typography>
                                                <Typography variant="body2" color={'#0068B4'} fontWeight={'bold'} paddingLeft={2}>
                                                    {item.tile.slug}
                                                </Typography>
                                            </Box>
                                            <Box flex={1} display={'flex'} flexDirection={'row'} paddingLeft={2} alignItems={'center'}>
                                                <Typography variant="body1" color="textSecondary">
                                                        Type :
                                                </Typography>
                                                <Typography variant="body2" color={'#0068B4'}fontWeight={'bold'} paddingLeft={2}>
                                                    {item.tile.tile_type}
                                                </Typography>
                                            </Box>
                                            <EditButton
                                                to={`/V2/tiles/${item.tile.id}/edit`}
                                                resource="tiles"
                                                record={item.tile}
                                                label="Edit"
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            )}
        </Box>
    );
};

export default GroupedList;
