import * as React from 'react';
import { AppBar, LoadingIndicator } from 'react-admin';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';

const CustomAppBar: React.FC<any> = (props: any) => {
    const [formattedUserName, setFormattedUserName] = useState('');

    React.useEffect(() => {
        // eslint-disable-next-line no-undef
        const userName = localStorage.getItem('CognitoIdentityServiceProvider.64114dkhu07rh418c1fpn9r5p.LastAuthUser');
        if (userName) {
            // clean userNAme by deleting "ActiveDirectory_" and "@accor.com"
            const [namePart] = userName.replace('ActiveDirectory_', '').split('@');
            setFormattedUserName(namePart);
        }
    }, []);
    return (
        <AppBar {...props} toolbar={
            <>
                <LoadingIndicator />
                <Box paddingLeft={2} paddingRight={2}>
                    <Typography variant='h6' >
                        {formattedUserName || 'Loading...'}
                    </Typography>
                </Box>
            </>
        }>
            <Box flex="1">
                <Typography variant="h6" id="react-admin-title"></Typography>
            </Box>
        </AppBar>

    );
};

export default CustomAppBar;
