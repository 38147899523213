import * as React from 'react';
import * as RA from 'react-admin';
import { PlatformType } from '../../types';

// const QuickFilter = (props: { source: string, label: string, defaultValue: any }) => (
//     <MUI.Chip sx={{ marginBottom: 1 }} label={props.label} />
// );

export const tilesFilters = () => (

    /* cconst {
            data,
            isLoading,
        } = RA.useGetList(
            'tiles',
        );
        const [autocompleteChoices, setAutocompleteChoices] = React.useState([]);

        React.useEffect(() => {
            if (!isLoading && data) {
                const choices = data.reduce(
                    (tileName, tile: TileV2) => [...tileName, {
                        id: tile.id,
                        name: tile.slug,
                    }],
                    [],
                );
                setAutocompleteChoices(choices);
            }
        }, [isLoading]); */

    [
        <RA.SelectInput
            source="platforms"
            choices={[
                {
                    id: PlatformType.ios,
                    name: 'iOS',
                },
                {
                    id: PlatformType.android,
                    name: 'Android',
                },
                {
                    id: PlatformType.any,
                    name: 'iOS & Android',
                },
            ]}
            validate={RA.required()}
        />,
        <RA.SearchInput source="slug" placeholder="Search by Title" alwaysOn/>,
        <RA.SelectInput
            source="tile_type"
            choices={[
                {
                    id: 'standard',
                    name: 'Standard Tile',
                },
                {
                    id: 'service',
                    name: 'Service Tile',
                },
            ]}
            validate={RA.required()}
        />,
    ]
);
