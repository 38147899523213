import React from 'react';
import * as MUI from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useRecordContext } from 'react-admin';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Edit, Settings } from '@mui/icons-material';
import TileTabs from './TileTabsV2';

import StandardContentPanel from './Panels/StandardContentPanelV2';
import ServiceContentPanel from '../../../../components/Tiles/Panels/Settings/ServiceContentPanelV2';
import { resetServiceTile, resetStandardTile } from '../../../../formReset/tileFormResetV2';
import SettingsPanel from '../../../../components/Tiles/Panels/Settings/SettingsPanelV2';

export const TileFormTabs = () => {
    const formContext = useFormContext();
    const record = useRecordContext();

    const type = formContext.getValues('tile_type');
    const [initalType, setInitialType] = React.useState(record ? record?.tile_type : '');

    React.useEffect(() => {
        if (record && type !== initalType) {
            if (type === 'standard') formContext.reset(resetStandardTile(record, type));
            if (type === 'service') formContext.reset(resetServiceTile(record, type));
            setInitialType(type);
        } else if (!record && type !== initalType) {
            if (type === 'standard') formContext.reset(resetStandardTile({}, type));
            if (type === 'service') formContext.reset(resetServiceTile({}, type));
            setInitialType(type);
        }
    }, [type]);

    return (
        <MUI.Box width="100%">
            <TileTabs
                icons={[<Edit />, <Settings />]}
                tabs={['Content', 'Settings']}
            >
                {type === 'service' ? <ServiceContentPanel /> : <StandardContentPanel />}
                <SettingsPanel/>
            </TileTabs>
        </MUI.Box>
    );
};
