import React, { useMemo } from 'react';
import { useGetList } from 'react-admin';
import { Box, CircularProgress, Typography } from '@mui/material';
import GroupedList from './ComponentsPage';

// Declaration of Interface Group
interface Group {
    componentId: string;
    componentTile: string;
    sectionName: string;
    items: Array<{
        id: string;
        createdAt: string;
        updatedAt: string;
        deletedAt: string | null;
        priority: number;
        tile: {
            id: string;
            slug: string;
            // other props of tile
        };
        component: {
            id: string;
            name: string;
            // other props of component
        };
    }>;
}

// Function to group tiles by component and associate sections to the component
const ComponentsList = () => {
    /* TODO: It’s possible to use another method to retrieve data using a React-admin component,
        such as the ReferenceManyToManyField. This component allows for managing relationships between many entities efficiently. */

    // Call Data
    const { data: componentsTilesData, isLoading: componentsTilesLoading, error: componentsTilesError } = useGetList('components-tiles');
    const { data: componentsSectionData, isLoading: componentsSectionLoading, error: componentsSectionError } = useGetList('components');

    /* Use memo for save data regrouped if componentsTilesData or componentsSectionData not ready return empty table */
    const groupedData = useMemo(() => {
        if (!componentsTilesData || !componentsSectionData) return [];

        // Creation map section | components by ID
        const componentsMap = componentsSectionData.reduce((acc, component) => {
            acc[component.id] = component;
            return acc;
        }, {});

        // Group tiles by component
        const groups = componentsTilesData.reduce<Record<string, Group>>((acc, item) => {
            const componentId = item.component.id;
            // Creation new group if component does not exist
            if (!acc[componentId]) {
                acc[componentId] = {
                    items: [],
                    componentId,
                    componentTile: item.component.name,
                    sectionName: componentsMap[componentId]?.section?.name || 'Unknown Section',
                };
            }
            // Add item of component
            acc[componentId].items.push(item);
            return acc;
        }, {});
        // Transformation to Group into Array
        return Object.entries(groups).map(([componentId, group]) => ({
            componentId,
            componentTile: group.componentTile,
            sectionName: group.sectionName,
            items: group.items,
        }));
    }, [componentsTilesData, componentsSectionData]);
    // Showing a loading indicator if data is loading
    if (componentsTilesLoading || componentsSectionLoading) {
        return <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems="center"
            justifyContent="center"
            height="100vh">
            <CircularProgress />;
            <Typography variant='h6'color={'GrayText'}>Please wait data dowload in progress</Typography>
        </Box>;
    }
    // Showing a error message if data don't loading
    if (componentsTilesError || componentsSectionError) {
        return <Typography color="error">Error loading data</Typography>;
    }
    // Retrun grouped data
    return (
        <Box>
            {groupedData.map((group) => (
                <Box key={group.componentId} mb={2}>
                    <GroupedList
                        // componentId={group.componentId}
                        componentTile={group.componentTile}
                        sectionName={group.sectionName}
                        items={group.items}
                    />
                </Box>
            ))}
        </Box>
    );
};

export default ComponentsList;
