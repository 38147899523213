import * as RA from 'react-admin';
import * as MUI from '@mui/material';
import { useFormContext } from 'react-hook-form';

// import { ReferenceManyInput } from '@react-admin/ra-relationships';
import { urlRegex } from '../../../../../constants';
import { LinkType } from '../../../../../types';

const LinkTypes = [
    { id: LinkType.browser, name: 'BROWSER' },
    { id: LinkType.deeplink, name: 'DEEPLINK' },
    { id: LinkType.webview, name: 'WEBVIEW' },
];

const StandardContentPanel = () => {
    const formContext = useFormContext();
    const data = formContext.getValues();
    console.log(data);

    return (
        <MUI.Box>
            {/* Images */}
            <MUI.Typography variant="h6" mt={3}>Images</MUI.Typography>
            <MUI.Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <RA.TextInput
                    source="imageUrl"
                    label="Picture"
                    validate={[
                        RA.required(),
                        RA.regex(
                            urlRegex,
                            'Picture must be a valid url',
                        ),
                    ]}
                    helperText={false}
                />
                <RA.TextInput
                    source="pictoUrl"
                    label="Picto"
                    validate={[
                        RA.regex(
                            urlRegex,
                            'Picto must be a valid url',
                        ),
                    ]}
                    helperText={false}
                />
            </MUI.Box>
            {/*
            <MUI.Typography variant="h6" mt={3}>content</MUI.Typography>
            <MUI.Box>
                <ReferenceManyInput reference="tile-contents" target="tile">
                    <RA.ArrayInput source="tileContents">
                        <RA.SimpleFormIterator>
                            <RA.TextInput source="title" label="Title" />
                            <RA.TextInput source="subtitle" label="Subtitle" />
                            <RA.TextInput source="incentive" label="Incentive" />
                            <RA.TextInput source="extendedText" label="Extended Text" />
                        </RA.SimpleFormIterator>
                    </RA.ArrayInput>
                </ReferenceManyInput>
            </MUI.Box>
            */}
            {/* Links */}
            <MUI.Typography variant="h6" mt={3}>Link</MUI.Typography>
            <MUI.Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <RA.TextInput
                    source="link"
                    label="Link"
                    validate={[
                        RA.required(),
                        RA.regex(
                            urlRegex,
                            'Link must be a valid url',
                        ),
                    ]}
                    helperText={'To vary the language, country and platform insert {lang}, {country} and/or {platform}'}
                    fullWidth
                />
                <RA.SelectInput
                    source="link_type"
                    label="Link type"
                    choices={LinkTypes}
                    // validate={RA.required()}
                />
            </MUI.Box>
        </MUI.Box>

    );
};

export default StandardContentPanel;
