import { FieldValues } from 'react-hook-form';
import { locales } from '../locales';

const resetLocale = locales.reduce((locale, value) => ({ ...locale, [value]: '' }), {});

export const resetLocaleStory = locales.reduce((locale, value) => ({ ...locale, [value]: [] }), {});

export const resetStory = [];

export const resetStandardTile = (record: FieldValues, tile_type: string) => ({
    ...record,
    tile_type,
    content: {
        ...record.content,
        title: resetLocale,
        subtitle: resetLocale,
        extended_text: resetLocale,
        incentive: resetLocale,
        link: '',
        link_type: '',
        picto: '',
        picture: '',
        tracking_label: '',
    },
});

export const resetServiceTile = (record: FieldValues, tile_type: string) => ({
    ...record,
    tile_type,
    content: {
        ...record.content,
        icon: {
            background_color: '',
            image: '',
        },
        title: resetLocale,
        incentive: resetLocale,
        link: '',
        link_type: '',
        extended_text: resetLocale,
        story_screens: resetStory,
        subtitle: resetLocale,
        tracking_label: '',
    },
});
