/* eslint-disable no-undef */
/** **
 * from https://github.com/raphiniert-com/ra-data-postgrest/blob/master/src/dataProvider/index.ts
 *** */
import { fetchAuthSession } from '@aws-amplify/auth';
import queryString from 'query-string';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    CreateResult, DataProvider, fetchUtils, Identifier,
} from 'ra-core';
import { HttpError } from 'react-admin';

// eslint-disable-next-line import/no-extraneous-dependencies

function manageBackendValidationErrors(error: HttpError): never {
    if (error.body?.details) {
        const matches = error.body.details.match(
            /Key \((.+)\)=\((.+)\) already exists./,
        );
        if (matches) {
            const [, key, value] = matches;
            throw new HttpError(
                `A record already exists with the value "${value}" for "${key}". Please choose another value.`,
                error.status,
            );
        }
    }
    throw new Error(error.body.message);
}

/**
 * Maps react-admin queries to a postgrest REST API
 *
 * This REST dialect uses postgrest syntax
 *
 * @see https://postgrest.org/en/stable/api.html#embedded-filters
 *
 * @example
 *
 * getList          => GET    http://my.api.url/posts?order=title.asc&offset=0&limit=24&filterField=eq.value
 * getOne           => GET    http://my.api.url/posts?id=eq.123
 * getMany          => GET    http://my.api.url/posts?id=in.(123,456,789)
 * getManyReference => GET    http://my.api.url/posts?author_id=eq.345
 * create           => POST   http://my.api.url/posts
 * update           => PATCH  http://my.api.url/posts?id=eq.123
 * updateMany       => PATCH  http://my.api.url/posts?id=in.(123,456,789)
 * delete           => DELETE http://my.api.url/posts?id=eq.123
 * deleteMany       => DELETE http://my.api.url/posts?id=in.(123,456,789)
 *
 * @example
 *
 * import * as React from 'react';
 * import { Admin, Resource } from 'react-admin';
 * import postgrestRestProvider from '@raphiniert/ra-data-postgrest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={postgrestRestProvider("http://path.to.my.api/")}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
function parseFilters(filter: Record<string, any>, defaultListOp: string) {
    const result: Record<string, any> = {};
    Object.keys(filter).forEach((key) => {
        // key: the name of the object key

        const splitKey = key.split('@');
        const operation = splitKey.length === 2 ? splitKey[1] : defaultListOp;

        let values: any;
        if (operation.includes('like')) {
            // we split the search term in words
            values = (filter[key] as string).trim().split(' ');
        } else {
            values = [filter[key]];
        }

        values.forEach((value: Record<string, any>) => {
            // eslint-disable-next-line no-nested-ternary
            const op = operation.includes('like')
                ? `${operation}.*${value}*`
                : Array.isArray(value)
                    ? `${operation}.{${value}}`
                    : `${operation}.${value}`;

            if (result[splitKey[0]] === undefined) {
                // first operator for the key, we add it to the dict
                result[splitKey[0]] = op;
            } else if (!Array.isArray(result[splitKey[0]])) {
                // second operator, we transform to an array
                result[splitKey[0]] = [result[splitKey[0]], op];
            } else {
                // third and subsequent, we add to array
                result[splitKey[0]].push(op);
            }
        });
    });

    return result;
}

// compound keys capability
type PrimaryKey = Array<string>;

const getPrimaryKey = (
    resource: string,
    primaryKeys: Map<string, PrimaryKey>,
) => {
    if (resource === 'app_config_users') {
        return ['pmid'];
    }

    if (resource === 'app_config_users_overrides') {
        return ['pmid', 'override_id'];
    }

    return primaryKeys.get(resource) || ['id'];
};

const isCompoundKey = (primaryKey: PrimaryKey): Boolean => primaryKey.length > 1;

const decodeId = (id: Identifier, primaryKey: PrimaryKey): string[] => {
    if (isCompoundKey(primaryKey)) {
        return JSON.parse(id.toString());
    }
    return [id.toString()];
};

const encodeId = (data: any, primaryKey: PrimaryKey): Identifier => {
    if (isCompoundKey(primaryKey)) {
        return JSON.stringify(primaryKey.map((key) => data[key]));
    }
    return data[primaryKey[0]];
};

const dataWithId = (data: any, primaryKey: PrimaryKey) => {
    if (data && data.id) {
        return data;
    }

    return Object.assign(data, {
        id: encodeId(data, primaryKey),
    });
};

const getQuery = (
    primaryKey: PrimaryKey,
    ids: Identifier | Array<Identifier>,
    resource: string,
): string => {
    if (Array.isArray(ids) && ids.length > 1) {
        // no standardized query with multiple ids possible for rpc endpoints which are api-exposed database functions
        if (resource.startsWith('rpc/')) {
            // eslint-disable-next-line no-console
            console.error(
                'PostgREST\'s rpc endpoints are not intended to be handled as views. Therefore, no query generation for multiple key values implemented!',
            );

            return '';
        }

        if (isCompoundKey(primaryKey)) {
            return `or=(
          ${ids.map((id) => {
        const primaryKeyParams = decodeId(id, primaryKey);
        return `and(${primaryKey
            .map((key, i) => `${key}.eq.${primaryKeyParams[i]}`)
            .join(',')})`;
    })}
        )`;
        }
        return queryString.stringify({
            [primaryKey[0]]: `in.(${ids.join(',')})`,
        });
    }
    // if ids is one Identifier
    const id: Identifier = ids.toString();
    const primaryKeyParams = decodeId(id, primaryKey);

    if (isCompoundKey(primaryKey)) {
        if (resource.startsWith('rpc/')) {
            return `${primaryKey
                .map((key: string, i: any) => `${key}=${primaryKeyParams[i]}`)
                .join('&')}`;
        }
        return `and=(${primaryKey
            .map((key: string, i: any) => `${key}.eq.${primaryKeyParams[i]}`)
            .join(',')})`;
    }
    return queryString.stringify({ [primaryKey[0]]: `eq.${id}` });
};

const getKeyData = (
    primaryKey: PrimaryKey,
    data: Record<string, any>,
): object => {
    if (isCompoundKey(primaryKey)) {
        return primaryKey.reduce(
            (keyData, key) => ({
                ...keyData,
                [key]: data[key],
            }),
            {},
        );
    }
    return { [primaryKey[0]]: data[primaryKey[0]] };
};

const getOrderBy = (field: string, order: string, primaryKey: PrimaryKey) => {
    if (field === 'id') {
        return primaryKey
            .map((key) => `${key}.${order.toLowerCase()}`)
            .join(',');
    }
    return `${field}.${order.toLowerCase()}`;
};

const defaultPrimaryKeys = new Map<string, PrimaryKey>();

const getUserOption = async (): Promise<{
    authenticated: boolean;
    token: string | undefined;
}> => {
    let userJwtToken;
    try {
        const session = await fetchAuthSession();
        if (session && session.tokens && session.tokens?.accessToken) {
            userJwtToken = session.tokens.accessToken;
        }
    } catch (e) {
        /* Ignore */
    }

    return {
        authenticated: !!userJwtToken,
        token: userJwtToken,
    };
};

export default (
    apiUrl: string,
    httpClient = fetchUtils.fetchJson,
    defaultListOp = 'eq',
    primaryKeys: Map<string, PrimaryKey> = defaultPrimaryKeys,
): DataProvider => ({
    getList: async (resource, params) => {
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const parsedFilter = parseFilters(params.filter, defaultListOp);

        const query = {
            order: getOrderBy(field, order, primaryKey),
            offset: (page - 1) * perPage,
            limit: perPage,
            ...parsedFilter,
        };

        // add header that Content-Range is in returned header
        const options = {
            headers: new Headers({
                Accept: 'application/json',
                Prefer: 'count=exact',
            }),
            user: await getUserOption(),
        };

        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

        return httpClient(url, options).then(({ headers, json }) => {
            if (!headers.has('content-range')) {
                throw new Error(
                    `The Content-Range header is missing in the HTTP Response. The postgREST data provider expects
          responses for lists of resources to contain this header with the total number of results to build
          the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?`,
                );
            }
            const contentRange = headers
                .get('content-range')
                ?.split('/')
                ?.pop();
            const total = contentRange ? parseInt(contentRange, 10) : 0;
            return {
                data: json.map((obj: any) => dataWithId(obj, primaryKey)),
                total,
            };
        });
    },

    getOne: async (resource, params) => {
        const { id } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const query = getQuery(primaryKey, id, resource);

        const url = `${apiUrl}/${resource}?${query}`;

        return httpClient(url, {
            headers: new Headers({
                accept: 'application/vnd.pgrst.object+json',
            }),
            user: await getUserOption(),
        }).then(({ json }) => ({
            data: dataWithId(json, primaryKey),
        }));
    },

    getMany: async (resource, params) => {
        const { ids } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const query = getQuery(primaryKey, ids, resource);

        const url = `${apiUrl}/${resource}?${query}`;

        return httpClient(url, { user: await getUserOption() }).then(
            ({ json }) => ({
                data: json.map((data: any) => dataWithId(data, primaryKey)),
            }),
        );
    },

    getManyReference: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const parsedFilter = parseFilters(params.filter, defaultListOp);
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const query = {
            [params.target]: `eq.${params.id}`,
            order: getOrderBy(field, order, primaryKey),
            offset: (page - 1) * perPage,
            limit: perPage,
            ...parsedFilter,
        };

        // add header that Content-Range is in returned header
        const options = {
            headers: new Headers({
                Accept: 'application/json',
                Prefer: 'count=exact',
            }),
            user: await getUserOption(),
        };

        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

        return httpClient(url, options).then(({ headers, json }) => {
            if (!headers.has('content-range')) {
                throw new Error(
                    `The Content-Range header is missing in the HTTP Response. The postgREST data provider expects
          responses for lists of resources to contain this header with the total number of results to build
          the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?`,
                );
            }
            const contentRange = headers
                .get('content-range')
                ?.split('/')
                ?.pop();
            const total = contentRange ? parseInt(contentRange, 10) : 0;
            return {
                data: json.map((data: any) => dataWithId(data, primaryKey)),
                total,
            };
        });
    },

    update: async (resource, params) => {
        const { id, data } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const query = getQuery(primaryKey, id, resource);

        const primaryKeyData = getKeyData(primaryKey, data);

        const url = `${apiUrl}/${resource}?${query}`;

        const body = JSON.stringify({
            ...data,
            ...primaryKeyData,
        });

        return httpClient(url, {
            method: 'PATCH',
            headers: new Headers({
                Accept: 'application/vnd.pgrst.object+json',
                Prefer: 'return=representation',
                'Content-Type': 'application/json',
            }),
            user: await getUserOption(),
            body,
        })
            .then(({ json }) => ({ data: dataWithId(json, primaryKey) }))
            .catch((error: HttpError) => manageBackendValidationErrors(error));
    },

    updateMany: async (resource, params) => {
        const { ids } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const query = getQuery(primaryKey, ids, resource);

        const body = JSON.stringify(
            params.data.map((obj: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { id, ...data } = obj;
                const primaryKeyData = getKeyData(primaryKey, data);

                return {
                    ...data,
                    ...primaryKeyData,
                };
            }),
        );

        const url = `${apiUrl}/${resource}?${query}`;

        return httpClient(url, {
            method: 'PATCH',
            headers: new Headers({
                Prefer: 'return=representation',
                'Content-Type': 'application/json',
            }),
            user: await getUserOption(),
            body,
        }).then(({ json }) => ({
            data: json.map((data: any) => encodeId(data, primaryKey)),
        }));
    },

    create: async (resource, params) => {
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const url = `${apiUrl}/${resource}`;

        return httpClient(url, {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/vnd.pgrst.object+json',
                Prefer: 'return=representation',
                'Content-Type': 'application/json',
            }),
            user: await getUserOption(),
            body: JSON.stringify(params.data),
        })
            .then(
                ({ json }) => {
                    const result : CreateResult = {
                        data: {
                            ...params.data,
                            id: encodeId(json, primaryKey),
                        },
                    };
                    return result;
                },
            )
            .catch((error: HttpError) => manageBackendValidationErrors(error));
    },

    delete: async (resource, params) => {
        const { id } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const query = getQuery(primaryKey, id, resource);

        const url = `${apiUrl}/${resource}?${query}`;

        return httpClient(url, {
            method: 'DELETE',
            headers: new Headers({
                Accept: 'application/vnd.pgrst.object+json',
                Prefer: 'return=representation',
                'Content-Type': 'application/json',
            }),
            user: await getUserOption(),
        }).then(({ json }) => ({ data: dataWithId(json, primaryKey) }));
    },

    deleteMany: async (resource, params) => {
        const { ids } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const query = getQuery(primaryKey, ids, resource);

        const url = `${apiUrl}/${resource}?${query}`;

        return httpClient(url, {
            method: 'DELETE',
            headers: new Headers({
                Prefer: 'return=representation',
                'Content-Type': 'application/json',
            }),
            user: await getUserOption(),
        }).then(({ json }) => ({
            data: json.map((data: any) => encodeId(data, primaryKey)),
        }));
    },
});
