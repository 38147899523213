import { Config } from '../../config';

export default (config: Config) => {
    const {
        VITE_ADMIN_BASE_URL,
        VITE_COGNITO_IDENTITY_POOL_ID,
        VITE_COGNITO_USER_POOL_ID,
        VITE_COGNITO_WEB_CLIENT_ID,
        VITE_COGNITO_DOMAINE,
    } = config;

    return {
        aws_project_region: 'eu-west-1',
        aws_cognito_identity_pool_id:
            VITE_COGNITO_IDENTITY_POOL_ID,
        aws_cognito_region: 'eu-west-1',
        aws_user_pools_id: VITE_COGNITO_USER_POOL_ID,
        aws_user_pools_web_client_id: VITE_COGNITO_WEB_CLIENT_ID,
        oauth: {
            domain: VITE_COGNITO_DOMAINE,
            scopes: ['email', 'openid', 'profile'],
            redirectSignIn: [VITE_ADMIN_BASE_URL],
            redirectSignOut: [VITE_ADMIN_BASE_URL],
            responseType: 'token' as const,
        },
        federationTarget: 'COGNITO_USER_POOLS',
    };
};
