import React from 'react';
import * as RA from 'react-admin';
import { Route } from 'react-router-dom';

// login
import dataProviderV2 from './providers/dataProvider/dataProviderV2';
import authProvider from './providers/authProvider';
import LoginV2 from './providers/authProvider/LoginV2';

// conf
import config from './config';

// components
import Dashboard from './ComponentsForAdminV2/ComponentsV2/AdminPage/ComponentsTilesMenu';
import CustomLayoutV2 from './layout/CustomLayoutV2';
import SectionsList from './ComponentsForAdminV2/ComponentsV2/AdminPage/SectionsList';
import ComponentsList from './ComponentsForAdminV2/ComponentsV2/AdminPage/Component/ComponentsList';
import { List, EditV2 } from './ComponentsForAdminV2/ComponentsV2/AdminPage/Tile/TilesV2';
import { lightTheme } from './ComponentsForAdminV2/ComponentsV2/ThemesV2/themesV2';

const AppV2 = () => {
    const { VITE_NEW_API_URL: newApiUrl } = config();

    return (
        <RA.Admin
            layout={CustomLayoutV2}
            authProvider={authProvider}
            dataProvider={dataProviderV2(newApiUrl)}
            loginPage={LoginV2}
            theme={lightTheme}
            store={RA.memoryStore()}
            requireAuth
            dashboard={Dashboard} // Definition of Dashboard component by default
        >
        // Route for new admin home page
            <RA.CustomRoutes>
                <Route path="/dashboard" element={<Dashboard />} />
            </RA.CustomRoutes>

            <RA.Resource
                name="components-tiles"
                options={{ label: 'Components+Tiles' }}
                list={ComponentsList} />
            <RA.Resource
                name="sections"
                options={{ label: 'Sections' }}
                list={SectionsList} />
                // for moment it's not possible to edit and create sections
            <RA.Resource
                name="components"
                options={{ label: 'Components' }}
                list={SectionsList} />
                // for moment it's not possible to edit and create components
            <RA.Resource
                name="tiles"
                list={List}
                edit={EditV2} />
            <RA.Resource name="tiles-filter" />
            <RA.Resource name="visa_filters" />
            <RA.Resource name="flying_partner_filters" />
            <RA.Resource name="cobrand_all_signature_filters" />
            <RA.Resource name="cobrand_woori_filters" />
            <RA.Resource name="reward_points_filters" />

        </RA.Admin>
    );
};

export default AppV2;
