import * as React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';

import { TilesFilters } from './TilesFilter';
import { locales } from '../../locales';
import { TilesPreviewItems } from './TilesPreviewItems';

// import { stringify } from 'qs';

export const TilesPreviewPage = (): React.ReactElement => {
    const [selectedLocale, setSelectedLocale] = React.useState(locales[0]);

    const [filters, setFilters] = React.useState({
        platforms: 'any',
        target: 'any',
        user_type: 'everyone',
    });

    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        data,
        total,
        isLoading,
        error,
    } = RA.useGetList(
        'tiles',
        {
            pagination: {
                page: 1,
                perPage: 1000,
            },
            sort: {
                field: 'id',
                order: 'ASC',
            },
            filter: filters,
        },
    );
    if (isLoading) {
        return <RA.Loading />;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    return (
        <MUI.Box my={2}>
            <RA.Title title={'Tiles preview'} />
            <MUI.Box display={'flex'} alignItems={'center'} gap={5} flexWrap={'wrap'} mb={1}>
                <MUI.Box justifyContent={'center'} gap={0} alignItems={'center'}>
                    <MUI.Typography variant="h6">Select your language</MUI.Typography>
                    <MUI.FormControl sx={{
                        marginTop: 0,
                    }}>
                        <MUI.InputLabel id="demo-simple-select-label">Langue</MUI.InputLabel>
                        <MUI.Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedLocale}
                            label="Age"
                            onChange={(e) => {
                                setSelectedLocale(e.target.value);
                            }}
                            sx={{
                                minWidth: 100,
                            }}
                        >
                            {locales.map((locale: string, index: number) => <MUI.MenuItem key={index} value={locale}>{locale}</MUI.MenuItem>)}
                        </MUI.Select>
                    </MUI.FormControl>
                </MUI.Box>
                <MUI.Box>
                    <MUI.Typography variant="h6">Filters</MUI.Typography>
                    <TilesFilters filters={filters} onChange={setFilters} />
                </MUI.Box>
            </MUI.Box>
            <TilesPreviewItems data={data} selectedLocale={selectedLocale} />
        </MUI.Box>
    );
};
