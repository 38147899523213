import * as React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';
import {
    Box, CircularProgress, Typography,
} from '@mui/material';
// import { useListContext } from 'react-admin';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    VisibilityOutlined, VisibilityOffOutlined,
} from '@mui/icons-material';
import { TilesForm } from './TilesFormV2';

import { BulkActionButtons } from '../../../../components/CustomButtons/BulkActionButtons';
import CustomEditTitleV2 from '../../CustomComponents/CustomTitleV2/CustomEditTitleV2';
import { tilesFilters } from '../../../../components/Filters/TilesListFiltersV2';

// eslint-disable-next-line import/no-extraneous-dependencies

const ListPagination = (props: RA.PaginationProps) => (
    <RA.Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const PostListActions = () => (
    <RA.TopToolbar sx={ { borderBottom: 'none' }}>
        <RA.SelectColumnsButton />
        <RA.FilterButton />
        <RA.CreateButton label='NEW TILE'/>
    </RA.TopToolbar>
);

/* const CustomRow = ({ record }) => (
    <Box
        bgcolor="#fff"
        // p={2}
        borderRadius="6px"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.2)"
        mb={1}
    >
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box flex={1} display="flex" alignItems="center" justifyContent="center">
                {record.published ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
            </Box>
            <Box flex={1} display="flex" alignItems="center">
                <Typography variant="h6">Tile:</Typography>
                <Box color="#0068C4" ml={1}>{record.slug}</Box>
            </Box>
            <Box flex={1} display="flex" alignItems="center" justifyContent="center">
                <Typography variant="body1">Type</Typography>
                <Box color="#0068C4" ml={1}>{record.tile_type}</Box>
            </Box>
        </Box>
    </Box>
); */

/* const CustomRowField = () => {
    const record = RA.useRecordContext();
    return <CustomRow record={record} />;
}; */

const ListContent = () => {
    const { data: tilesData, isLoading: tilesLoading, error: tilesError } = RA.useGetList('tiles');
    //  const { error, isLoading } = useListContext();
    if (tilesError || !tilesData) {
        return (
            <Typography variant="h6" color="red">
                Data download Error
            </Typography>
        );
    }
    if (tilesLoading) {
        return (
            <Box>
                <CircularProgress />
                <Typography variant="h6" color="GrayText">
                Please wait, data download in progress
                </Typography>
            </Box>
        );
    }
    const rowStyle = () => ({
        borderRadius: '6px',
        margintop: '10px',
        mb: '20px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    });

    // Const for tile data
    const record = tilesData[0];

    return (
        <Box>
            <RA.DatagridConfigurable
                bulkActionButtons={<BulkActionButtons />}
                rowSx={rowStyle}
                // sx for delete grey border in row
                /* sx={{
                    // for header row
                     '& .RaDatagrid-headerCell': {
                        borderBottom: 'none',
                    },
                    // for content row
                    '& .RaDatagrid-rowCell': {
                        borderBottom: 'none',
                    },
                    // for checkbox
                    '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingCheckbox.MuiTableCell-sizeSmall.css-ani8uw-MuiTableCell-root': {
                        borderBottom: 'none',
                    },
                }} */
            >
                <RA.BooleanField
                    source="published"
                    label="Visible"
                    TrueIcon={VisibilityOutlined}
                    FalseIcon={VisibilityOffOutlined}
                />
                <RA.TextField source="slug" label={'Title'} variant="body2" color={'#0068B4'}/>
                <RA.TextField source="tile_type" label="Type" color={'#999999'} fontWeight={'bold'}/>
                <Box display={'flex'} flex={1} flexDirection={'row' } justifyContent={'space-between'} paddingLeft={4} paddingRight={2}>
                    <RA.CloneButton />
                    <RA.EditButton
                        to={`/V2/tiles/${record?.id}/edit`}
                        resource="tiles"
                        label="EDIT"/>
                    <RA.DeleteButton />
                </Box>
            </RA.DatagridConfigurable>
        </Box>
    );
};

export const List = ({ resource }): React.ReactElement => (
    <MUI.Box display="flex" flexDirection="row">
        <MUI.Box flex={1}>
            <RA.List
                title={'TILES'}
                resource={resource}
                perPage={25}
                pagination={<ListPagination />}
                filters={tilesFilters()}
                actions={<PostListActions />}
                emptyWhileLoading
                empty={<Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="100vh"
                >
                </Box>}
                sx={{
                    '& .RaList-content': {
                        padding: '20px',
                    },
                }}
            >
                <ListContent />
            </RA.List>
        </MUI.Box>
    </MUI.Box>
);

const CreateToolBar = (props: RA.ToolbarProps): React.ReactElement => (
    <RA.Toolbar {...props}>
        <RA.SaveButton
            type="button"
            alwaysEnable
        />
    </RA.Toolbar>
);

export const Create = (): React.ReactElement => (
    <RA.Create redirect={'list'}>
        <TilesForm toolbar={<CreateToolBar />} />
    </RA.Create>
);

const EditToolBar = (): React.ReactElement => (
    <RA.Toolbar sx={{ gap: 4 }}>
        <RA.SaveButton
            type="button"
            alwaysEnable
        />
        <RA.DeleteWithConfirmButton />
    </RA.Toolbar>
);

export const EditV2 = (): React.ReactElement => (
    <RA.Edit redirect={'/V2/dashboard'} mutationMode="pessimistic" title={<CustomEditTitleV2 name={'tile'} field={'slug' } />}>
        <TilesForm toolbar={<EditToolBar />}/>
    </RA.Edit>
);
