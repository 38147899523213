/* eslint-disable import/extensions */
import * as RA from 'react-admin';
import * as MUI from '@mui/material';

import {
    AllPlusCard, CobrandAllSignature, CobrandFlyingPartner, CobrandVisas, ExpirationTime, PlatformType, RuleType, Target, Tiering, UserType, WooriCard,
} from '../../../../types';

import { countryListAllIsoData } from '../../../../locales';
import {
    validateAllPlusCard, validateAllSignature, validateFlyingPartner, validateWooriCard,
} from '../../../../formValidations/validateFlyingPartner';
import validateCardExpiration from '../../../../formValidations/validateCardExpiration';
import rewardPointsValidation from '../../../../formValidations/validateRewardPoints';

import CheckBoxInputWithSelectAll from '../../../CustomInputs/CheckBoxInputWithSelectAll';
import AutocompleteCountriesInput from '../../../CustomInputs/AutocompleteCountriesInput';
import getOnChangeFunctions, { isUserProspectFromFormData } from './onChangeFunctions';

const SettingsPanel = () => {
    const onChange = getOnChangeFunctions();

    return (
        <MUI.Box width="100%">
            {
                /**
                 * ******
                 * TARGET
                 * ******
                 */
            }
            <MUI.Typography variant="h6" mt={3}>Target</MUI.Typography>
            <RA.SelectInput
                source="target"
                choices={[
                    {
                        id: Target.carousel,
                        name: 'Carousel',
                    },
                    {
                        id: Target.appHome,
                        name: 'AppHome',
                    },
                    {
                        id: Target.any,
                        name: 'Carousel & appHome',
                    },
                ]}
                validate={RA.required()}
                helperText={false}
                fullWidth
            />

            {
                /**
                 * ********
                 * LOCATION
                 * ********
                 */
            }
            <MUI.Typography variant="h6" mt={3}>Location</MUI.Typography>
            <AutocompleteCountriesInput
                source="countries"
                choices={countryListAllIsoData}
                helperText={false}
                translateChoice={false}
            />
            <RA.SelectInput
                source="countries_rule"
                choices={[
                    {
                        id: RuleType.include,
                        name: 'Inclusion list',
                    },
                    {
                        id: RuleType.exclude,
                        name: 'Exclusion list',
                    },
                    {
                        id: RuleType.none,
                        name: 'No rule',
                    },
                ]}
                defaultValue={RuleType.none}
                helperText={false}
                fullWidth
            />

            {
                /**
                 * *********
                 * PROFILING
                 * *********
                 */
            }
            <MUI.Typography variant="h6" mt={3}>Profiling</MUI.Typography>
            <RA.SelectInput
                source="platforms"
                choices={[
                    {
                        id: PlatformType.ios,
                        name: 'iOS',
                    },
                    {
                        id: PlatformType.android,
                        name: 'Android',
                    },
                    {
                        id: PlatformType.any,
                        name: 'iOS & Android',
                    },
                ]}
                validate={RA.required()}
                helperText={false}
                fullWidth
            />

            <MUI.Box display="flex" flexDirection={'row'} alignItems={'flex-start'} gap={1}>
                <RA.SelectInput
                    source="user_type"
                    choices={[
                        {
                            id: UserType.everyone,
                            name: 'Everyone',
                        },
                        {
                            id: UserType.accorPlus,
                            name: 'Accor Plus (D1, D2, ...)',
                        },
                        {
                            id: UserType.memberAllNoAccorPlus,
                            name: 'Membres ALL sans les Accor Plus (A1, A2, ...)',
                        },
                        {
                            id: UserType.everyMemberAll,
                            name: 'Membres ALL (A* & D* & P*)',
                        },
                        {
                            id: UserType.prospect,
                            name: 'Prospect (pas de carte ou pas connecté)',
                        },
                    ]}
                    validate={RA.required()}
                    onChange={onChange.userType}
                    helperText={false}
                    fullWidth
                />
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => [UserType.memberAllNoAccorPlus, UserType.everyMemberAll, UserType.accorPlus].includes(
                        formData.user_type,
                    ) && (
                        <RA.AutocompleteArrayInput
                            source="tiering"
                            choices={[
                                {
                                    id: Tiering.classic,
                                    name: 'classic',
                                },
                                {
                                    id: Tiering.silver,
                                    name: 'silver',
                                },
                                {
                                    id: Tiering.gold,
                                    name: 'gold',
                                },
                                {
                                    id: Tiering.platinum,
                                    name: 'platinum',
                                },
                                {
                                    id: Tiering.diamond,
                                    name: 'diamond',
                                },
                                {
                                    id: Tiering.limitless,
                                    name: 'limitless',
                                },
                            ]}
                            helperText="Leave empty for every tiering"
                            fullWidth
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => [UserType.memberAllNoAccorPlus, UserType.everyMemberAll, UserType.accorPlus].includes(
                        formData.user_type,
                    ) && (
                        <RA.SelectInput
                            source="user_card_expiration"
                            choices={[
                                {
                                    id: ExpirationTime.anytime,
                                    name: 'Anytime',
                                },
                                {
                                    id: ExpirationTime.oneMonth,
                                    name: '1 Month',
                                },
                                {
                                    id: ExpirationTime.twoMonths,
                                    name: '2 Months',
                                },
                                {
                                    id: ExpirationTime.threeMonths,
                                    name: '3 Months',
                                },
                            ]}
                            validate={validateCardExpiration}
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
            </MUI.Box>

            {
                /**
                 * *******
                 * FILTERS
                 * *******
                 */
            }
            <MUI.Typography variant="h6" mt={3}>Filters</MUI.Typography>
            <RA.FormDataConsumer>
                {({
                    formData,
                    ...rest
                }) => !isUserProspectFromFormData(formData)
                    && <RA.BooleanInput
                        source="filter_by_cobrand_visa"
                        label="Filter by cobrand VISA"
                        onChange={onChange.filterByCobrandVisa}
                        helperText={false}
                        fullWidth
                        {...rest}
                    />
                }
            </RA.FormDataConsumer>
            <RA.FormDataConsumer
            >
                {({
                    formData,
                    ...rest
                }) => formData.filter_by_cobrand_visa && (
                    <CheckBoxInputWithSelectAll
                        source="cobrand_visa"
                        label="Cobrand VISA Type"
                        row={false}
                        choices={[
                            {
                                id: CobrandVisas.CGPREC,
                                name: 'CGPREC — Ultimate (Allemagne)',
                            },
                            {
                                id: CobrandVisas.CGMCB2,
                                name: 'CGMCB2 — Explorer - Comfort',
                            },
                            {
                                id: CobrandVisas.CGMCB1,
                                name: 'CGMCB1 — Explorer - Active',
                            },
                            {
                                id: CobrandVisas.CGECB2,
                                name: 'CGECB2 — Pulse - Smart (Allemagne)',
                            },
                            {
                                id: CobrandVisas.CGECB1,
                                name: 'CGECB1 — Pulse - Easy (Allemagne)',
                            },
                            {
                                id: CobrandVisas.CFPREC,
                                name: 'CFPREC — Ultimate (France)',
                            },
                            {
                                id: CobrandVisas.CFMCB2,
                                name: 'CFMCB2 — Explorer - Zen',
                            },
                            {
                                id: CobrandVisas.CFMCB1,
                                name: 'CFMCB1 — Explorer - Mobility',
                            },
                            {
                                id: CobrandVisas.CFECB2,
                                name: 'CFECB2 — Pulse - Smart (France)',
                            },
                            {
                                id: CobrandVisas.CFECB1,
                                name: 'CFECB1 — Pulse - Easy (France)',
                            },
                            {
                                id: CobrandVisas.ADCBSC,
                                name: 'ADCBSC — ALL - ADCB Signature',
                            },
                            {
                                id: CobrandVisas.ADCBIC,
                                name: 'ADCBIC — ALL - ADCB Infinite',
                            },
                        ]}
                        fullWidth
                        {...rest}
                    />
                )
                }
            </RA.FormDataConsumer>

            <RA.FormDataConsumer>
                {({
                    formData,
                    ...rest
                }) => !isUserProspectFromFormData(formData)
                    && <RA.BooleanInput
                        source="filter_by_cobrand_flying_partner"
                        label="Filter by cobrand Flying Partner"
                        onChange={onChange.filterByCobrandFlyingPartner}
                        {...rest}
                        helperText={false}
                    />
                }
            </RA.FormDataConsumer>
            <MUI.Box
                display="flex"
                flexDirection={'row'}
                alignItems={'center'}
                flexGrow={1}
                flexWrap={'wrap'}
                gap={4}
            >
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => formData.filter_by_cobrand_flying_partner && (
                        <RA.AutocompleteArrayInput
                            source="cobrand_flying_partner"
                            label="Flying partners list"
                            choices={[
                                {
                                    id: CobrandFlyingPartner.NoSubscription,
                                    name: 'No subscription',
                                },
                                {
                                    id: CobrandFlyingPartner.AirFrance,
                                    name: 'Air France',
                                },
                                {
                                    id: CobrandFlyingPartner.Qantas,
                                    name: 'Qantas',
                                },
                                {
                                    id: CobrandFlyingPartner.Qatar,
                                    name: 'Qatar',
                                },
                            ]}
                            validate={validateFlyingPartner}
                            helperText={false}
                            sx={{ width: '50%' }}
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => formData.filter_by_cobrand_flying_partner && (
                        <RA.RadioButtonGroupInput
                            label="List Type"
                            source="flying_partner_rule"
                            choices={[
                                {
                                    id: 'include',
                                    name: 'Inclusion list',
                                },
                                {
                                    id: 'exclude',
                                    name: 'Exclusion list',
                                },
                            ]}
                            defaultValue="include"
                            helperText={false}
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
            </MUI.Box>

            <RA.FormDataConsumer>
                {({
                    formData,
                    ...rest
                }) => !isUserProspectFromFormData(formData)
                    && <RA.BooleanInput
                        source="filter_by_cobrand_all_signature"
                        label="Filter by cobrand All Signature"
                        onChange={onChange.filterByCobrandAllSignature}
                        {...rest}
                        helperText={false}
                    />
                }
            </RA.FormDataConsumer>
            <MUI.Box
                display="flex"
                flexDirection={'row'}
                alignItems={'center'}
                flexGrow={1}
                flexWrap={'wrap'}
                gap={4}
            >
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => formData.filter_by_cobrand_all_signature && (
                        <RA.AutocompleteArrayInput
                            source="cobrand_all_signature"
                            label="All Signature list"
                            choices={[
                                {
                                    id: CobrandAllSignature.NoSubscription,
                                    name: 'No subscription',
                                },
                                {
                                    id: CobrandAllSignature.SIGPL1,
                                    name: 'ALL Signature - Plan 1',
                                },
                                {
                                    id: CobrandAllSignature.SIGPL2,
                                    name: 'ALL Signature - Plan 2',
                                },
                                {
                                    id: CobrandAllSignature.SIGPL3,
                                    name: 'ALL Signature - Plan 3',
                                },
                            ]}
                            validate={validateAllSignature}
                            helperText={false}
                            sx={{ width: '50%' }}
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => formData.filter_by_cobrand_all_signature && (
                        <RA.RadioButtonGroupInput
                            label="List Type"
                            source="all_signature_rule"
                            choices={[
                                {
                                    id: 'include',
                                    name: 'Inclusion list',
                                },
                                {
                                    id: 'exclude',
                                    name: 'Exclusion list',
                                },
                            ]}
                            defaultValue="include"
                            helperText={false}
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
            </MUI.Box>

            <RA.FormDataConsumer>
                {({
                    formData,
                    ...rest
                }) => !isUserProspectFromFormData(formData)
                    && <RA.BooleanInput
                        source="filter_by_all_plus_card"
                        label="Filter by All Plus Card"
                        onChange={onChange.filterByAllPlusCard}
                        {...rest}
                        helperText={false}
                    />
                }
            </RA.FormDataConsumer>
            <MUI.Box
                display="flex"
                flexDirection={'row'}
                alignItems={'center'}
                flexGrow={1}
                flexWrap={'wrap'}
                gap={4}
            >
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => formData.filter_by_all_plus_card && (
                        <RA.AutocompleteArrayInput
                            source="all_plus_card"
                            label="All-Plus Card list"
                            choices={[
                                {
                                    id: AllPlusCard.FI,
                                    name: 'ALL Plus IBIS',
                                },
                                {
                                    id: AllPlusCard.B3,
                                    name: 'ALL Plus Voyageur',
                                },

                            ]}
                            validate={validateAllPlusCard}
                            helperText={false}
                            sx={{ width: '50%' }}
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => formData.filter_by_all_plus_card && (
                        <RA.RadioButtonGroupInput
                            label="List Type"
                            source="all_plus_card_rule"
                            choices={[
                                {
                                    id: 'include',
                                    name: 'Inclusion list',
                                },
                                {
                                    id: 'exclude',
                                    name: 'Exclusion list',
                                },
                            ]}
                            defaultValue="include"
                            helperText={false}
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
            </MUI.Box>

            <RA.FormDataConsumer>
                {({
                    formData,
                    ...rest
                }) => !isUserProspectFromFormData(formData)
                    && <RA.BooleanInput
                        source="filter_by_woori_card"
                        label="Filter by Cobrand Woori"
                        onChange={onChange.filterByWooriCard}
                        {...rest}
                        helperText={false}
                    />
                }
            </RA.FormDataConsumer>
            <MUI.Box
                display="flex"
                flexDirection={'row'}
                alignItems={'center'}
                flexGrow={1}
                flexWrap={'wrap'}
                gap={4}
            >
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => formData.filter_by_woori_card && (
                        <RA.AutocompleteArrayInput
                            source="woori_card"
                            label="Woori Card list"
                            choices={[
                                {
                                    id: WooriCard.WOOID1,
                                    name: 'Woori Premium',
                                },
                                {
                                    id: WooriCard.WOOID2,
                                    name: 'Woori Infinite',
                                },

                            ]}
                            validate={validateWooriCard}
                            helperText={false}
                            sx={{ width: '50%' }}
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => formData.filter_by_woori_card && (
                        <RA.RadioButtonGroupInput
                            label="List Type"
                            source="woori_card_rule"
                            choices={[
                                {
                                    id: 'include',
                                    name: 'Inclusion list',
                                },
                                {
                                    id: 'exclude',
                                    name: 'Exclusion list',
                                },
                            ]}
                            defaultValue="include"
                            helperText={false}
                            {...rest}
                        />
                    )
                    }
                </RA.FormDataConsumer>
            </MUI.Box>

            <MUI.Box display="flex" alignItems="center">
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => !isUserProspectFromFormData(formData)
                        && <RA.BooleanInput
                            source="reward_points_minimum_enabled"
                            label="Minimum Reward Points"
                            onChange={onChange.rewardPointsMinimumEnabled}
                            helperText={false}
                            sx={{ width: '30%' }}
                            {...rest}
                        />
                    }
                </RA.FormDataConsumer>
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => (
                        !isUserProspectFromFormData(formData)
                        && <RA.NumberInput
                            source="reward_points_minimum"
                            defaultValue={0}
                            min={0}
                            disabled={
                                !formData.reward_points_minimum_enabled
                            }
                            validate={rewardPointsValidation}
                            helperText={false}
                            {...rest}
                        />
                    )}
                </RA.FormDataConsumer>
            </MUI.Box>
            <MUI.Box display="flex" alignItems="center">
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => !isUserProspectFromFormData(formData)
                        && <RA.BooleanInput

                            source="reward_points_maximum_enabled"
                            label="Maximum Reward Points"
                            onChange={onChange.rewardPointsMaximumEnabled}
                            helperText={false}
                            sx={{ width: '30%' }}
                            {...rest}
                        />

                    }
                </RA.FormDataConsumer>
                <RA.FormDataConsumer>
                    {({
                        formData,
                        ...rest
                    }) => (
                        !isUserProspectFromFormData(formData)
                        && <RA.NumberInput
                            source="reward_points_maximum"
                            defaultValue={0}
                            min={0}
                            disabled={
                                !formData.reward_points_maximum_enabled
                            }
                            validate={rewardPointsValidation}
                            helperText={false}
                            {...rest}
                        />
                    )}
                </RA.FormDataConsumer>
            </MUI.Box>

            {
                /**
                 * *******
                 * TRACKING
                 * *******
                 */
            }
            <MUI.Typography variant="h6" mt={3}>Tracking</MUI.Typography>
            <RA.TextInput
                source="tracking_label"
                fullWidth
            />
        </MUI.Box>
    );
};

export default SettingsPanel;
