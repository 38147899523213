import * as React from 'react';
import * as RA from 'react-admin';
// import * as MUI from '@mui/material';
import { PlatformType, Target, Tile } from '../../types';

// const QuickFilter = (props: { source: string, label: string, defaultValue: any }) => (
//     <MUI.Chip sx={{ marginBottom: 1 }} label={props.label} />
// );

export const tilesFilters = () => {
    const {
        data,
        isLoading,
    } = RA.useGetList(
        'tiles',
    );
    const [autocompleteChoices, setAutocompleteChoices] = React.useState([]);

    React.useEffect(() => {
        if (!isLoading && data) {
            const choices = data.reduce(
                (tileName, tile: Tile) => [...tileName, {
                    id: tile.tilename,
                    name: tile.tilename,
                }],
                [],
            );
            setAutocompleteChoices(choices);
        }
    }, [isLoading]);

    return [
        <RA.AutocompleteInput source={'tilename'} choices={autocompleteChoices} label="tap a Title" alwaysOn sx={{ minWidth: 200 }} />,
        <RA.SelectInput
            source="platforms"
            choices={[
                {
                    id: PlatformType.ios,
                    name: 'iOS',
                },
                {
                    id: PlatformType.android,
                    name: 'Android',
                },
                {
                    id: PlatformType.any,
                    name: 'iOS & Android',
                },
            ]}
            validate={RA.required()}
        />,
        <RA.SelectInput
            source="target"
            choices={[
                {
                    id: Target.carousel,
                    name: 'Carousel',
                },
                {
                    id: Target.appHome,
                    name: 'AppHome',
                },
                {
                    id: Target.any,
                    name: 'Carousel & appHome',
                },
            ]}
            validate={RA.required()}
            helperText={false}
            fullWidth
        />,
        <RA.SelectInput
            source="type"
            choices={[
                {
                    id: 'standard',
                    name: 'Standard Tile',
                },
                {
                    id: 'service',
                    name: 'Service Tile',
                },
            ]}
            validate={RA.required()}
        />,
    ];
};
