import React from 'react';
import * as MUI from '@mui/material';

import { Tile } from '../Tile';
import { Tile as TileType } from '../../types';

type TilesPreviewProps = any & {
    selectedLocale: string;
    data: TileType;
};

export const TilesPreview = ({
    selectedLocale,
    data,
}: TilesPreviewProps): React.ReactElement => (
    <MUI.Box
        position="relative"
        padding="16px 0"
    >
        <MUI.Typography align="center" fontWeight="bold" lineHeight="50px" variant="h6">
            Preview
        </MUI.Typography>
        <MUI.Box
            sx={{
                height: 'calc(100vh - 200px)',
                overflowY: 'scroll',
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }}
        >
            <MUI.Box>
                <MUI.Box
                    position="relative"
                >
                    <Tile
                        tile={{
                            picture: data?.picture,
                            title: data?.title?.[selectedLocale],
                            subtitle: data?.subtitle?.[selectedLocale],
                            incentive: data?.incentive?.[selectedLocale],
                            picto: data?.picto,
                        }}
                    />
                    {selectedLocale
                        && <MUI.Box
                            border={'1px solid gray'}
                            position="absolute"
                            sx={{
                                top: 0,
                                left: 0,
                                textTransform: 'uppercase',
                                border: '1px solid #ccc',
                                background: 'rgba(255, 255, 255, 0.6)',
                                borderRadius: 2,
                                padding: '5px 0',
                                width: 60,
                                fontWeight: 'bold',
                                textAlign: 'center',
                            }}
                        >
                            {selectedLocale}
                        </MUI.Box>
                    }
                </MUI.Box>
            </MUI.Box>
        </MUI.Box>
    </MUI.Box>
);
