import * as MUI from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import AddIcon from '@mui/icons-material/Add';

const StoryScreenCreateAction = ({ handleOpenId }) => (
    <MUI.Button
        variant="text"
        color="info"
        onClick={handleOpenId('new')}
        endIcon={<AddIcon />}
    >
        Add
    </MUI.Button>
);

export default StoryScreenCreateAction;
