import * as React from 'react';
import { AppBar } from 'react-admin';
import { Box, Typography } from '@mui/material';

const CustomAppBar: React.FC<any> = (props: any) => (
    <AppBar {...props} >
        <Box flex="1">
            <Typography variant="h6" id="react-admin-title"></Typography>
        </Box>
    </AppBar>
);

export default CustomAppBar;
