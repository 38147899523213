import { fetchAuthSession } from '@aws-amplify/auth';
import queryString from 'query-string';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    CreateResult, fetchUtils, DataProvider, Identifier,
} from 'ra-core';
import { HttpError } from 'react-admin';

function manageBackendValidationErrors(error: HttpError): never {
    if (error.body?.details) {
        const matches = error.body.details.match(
            /Key \((.+)\)=\((.+)\) already exists./,
        );
        if (matches) {
            const [, key, value] = matches;
            throw new HttpError(
                `A record already exists with the value "${value}" for "${key}". Please choose another value.`,
                error.status,
            );
        }
    }
    throw new Error(error.body.message);
}

// compound keys capability
type PrimaryKey = Array<string>;

const getPrimaryKey = (
    resource: string,
    primaryKeys: Map<string, PrimaryKey>,
) => {
    if (resource === 'app_config_users') {
        return ['pmid'];
    }

    if (resource === 'app_config_users_overrides') {
        return ['pmid', 'override_id'];
    }

    return primaryKeys.get(resource) || ['id'];
};

const isCompoundKey = (primaryKey: PrimaryKey): Boolean => primaryKey.length > 1;

const encodeId = (data: any, primaryKey: PrimaryKey): Identifier => {
    if (isCompoundKey(primaryKey)) {
        return JSON.stringify(primaryKey.map((key) => data[key]));
    }
    return data[primaryKey[0]];
};

const dataWithId = (data: any, primaryKey: PrimaryKey) => {
    if (data && data.id) {
        return data;
    }

    return Object.assign(data, {
        id: encodeId(data, primaryKey),
    });
};
const getQuery = (
    primaryKey: PrimaryKey,
    ids: Identifier | Array<Identifier>,
    resource: string,
): string => {
    if (Array.isArray(ids)) {
        console.log(resource);
        return ids.join(',');
    }
    return ids.toString();
};

const getKeyData = (
    primaryKey: PrimaryKey,
    data: Record<string, any>,
): object => {
    if (isCompoundKey(primaryKey)) {
        return primaryKey.reduce(
            (keyData, key) => ({
                ...keyData,
                [key]: data[key],
            }),
            {},
        );
    }
    return { [primaryKey[0]]: data[primaryKey[0]] };
};

const getOrderBy = (field: string, order: string, primaryKey: PrimaryKey) => {
    if (field === 'id') {
        return primaryKey
            .map((key) => `${key}.${order.toLowerCase()}`)
            .join(',');
    }
    return `${field}.${order.toLowerCase()}`;
};

const defaultPrimaryKeys = new Map<string, PrimaryKey>();

const getUserOption = async (): Promise<{
    authenticated: boolean;
    token: string | undefined;
}> => {
    let userJwtToken;
    try {
        const session = await fetchAuthSession();
        if (session && session.tokens && session.tokens?.accessToken) {
            userJwtToken = session.tokens.accessToken;
        }
    } catch (e) {
        console.error(e);
        /* Ignore */
    }

    return { authenticated: !!userJwtToken, token: userJwtToken };
};

export default (
    apiUrl: string,
    httpClient = fetchUtils.fetchJson,
    primaryKeys: Map<string, PrimaryKey> = defaultPrimaryKeys,
): DataProvider => ({
    getList: async (resource, params) => {
        const primaryKey = getPrimaryKey(resource, primaryKeys);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const parsedFilter = params.filter;

        const expandsValue = () => {
            if (resource === 'components-tiles') {
                return 'tile,component';
            } if (resource === 'sections') {
                return 'components';
            } if (resource === 'components') {
                return 'section';
            } if (resource === 'tiles-filter') {
                return 'visa,flyingPartner,cobrandAllSignature,cobrandWoori,allPlusCard,rewardPoints';
            }
            return '';
        };

        const query = {
            order: getOrderBy(field, order, primaryKey),
            offset: (page - 1) * perPage,
            limit: perPage,
            ...parsedFilter,
            expands: expandsValue(),
        };

        // Add header that Content-Range is in returned header
        const options = {
            headers: new Headers({
                Accept: 'application/json',
            }),
            user: await getUserOption(),
        };

        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

        return httpClient(url, options).then(({ json }) => {
            const total = json.count;
            return {
                data: json.records.map((obj: any) => dataWithId(obj, primaryKey)),
                total,
            };
        });
    },

    getOne: async (resource, params) => {
        const { id } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);
        const expandsValue = () => {
            if (resource === 'components-tiles') {
                return 'tile,component';
            } if (resource === 'sections') {
                return 'components';
            } if (resource === 'components') {
                return 'section';
            } if (resource === 'tiles') {
                return '';
            } if (resource === 'tiles-filter') {
                return 'visa,flyingPartner,cobrandAllSignature,cobrandWoori,allPlusCard,rewardPoints';
            }
            return '';
        };

        const query = {
            expands: expandsValue(),
        };

        const url = `${apiUrl}/${resource}/${getQuery(primaryKey, id, resource)}?${queryString.stringify(query)}`;
        return httpClient(url, {
            headers: new Headers({
                Accept: 'application/json',
            }),
            user: await getUserOption(),
        }).then(({ json }) => ({
            data: dataWithId(json, primaryKey),
        }));
    },

    getMany: async (resource, params) => {
        const { ids } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const query = getQuery(primaryKey, ids, resource);

        const url = `${apiUrl}/${resource}/${query}`;

        return httpClient(url, { user: await getUserOption() }).then(
            ({ json }) => ({
                data: json.records.map((data: any) => dataWithId(data, primaryKey)),
            }),
        );
    },

    getManyReference: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const parsedFilter = params.filter;
        const primaryKey = getPrimaryKey(resource, primaryKeys);
        const expandsValue = () => {
            if (resource === 'components-tiles') {
                return 'tile,component';
            } if (resource === 'sections') {
                return 'components';
            } if (resource === 'components') {
                return 'section';
            } if (resource === 'tiles') {
                return 'tileFilter,storyScreens';
            } if (resource === 'tiles-filter') {
                return 'visa,flyingPartner,cobrandAllSignature,cobrandWoori,allPlusCard,rewardPoints';
            }
            return '';
        };
        const query = {
            [params.target]: `${params.id}`,
            order: getOrderBy(field, order, primaryKey),
            offset: (page - 1) * perPage,
            limit: perPage,
            ...parsedFilter,
            expands: expandsValue(),
        };

        const options = {
            headers: new Headers({
                Accept: 'application/json',
            }),
            user: await getUserOption(),
        };

        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

        return httpClient(url, options).then(({ json }) => {
            const total = json.count;
            return {
                data: json.records.map((data: any) => dataWithId(data, primaryKey)),
                total,
            };
        });
    },

    update: async (resource, params) => {
        const { id, data } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);
        const query = getQuery(primaryKey, id, resource);
        const primaryKeyData = getKeyData(primaryKey, data);
        const url = `${apiUrl}/${resource}/${query}`;
        const body = JSON.stringify({
            ...data,
            ...primaryKeyData,
        });

        return httpClient(url, {
            method: 'PATCH',
            headers: new Headers({
                Accept: 'application/vnd.pgrst.object+json',
                Prefer: 'return=representation',
                'Content-Type': 'application/json',
            }),
            user: await getUserOption(),
            body,
        })
            .then(({ json }) => ({ data: dataWithId(json, primaryKey) }))
            .catch((error: HttpError) => manageBackendValidationErrors(error));
    },

    updateMany: async (resource, params) => {
        const { ids } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const query = getQuery(primaryKey, ids, resource);

        const body = JSON.stringify(
            params.data.map((obj: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { id, ...data } = obj;
                const primaryKeyData = getKeyData(primaryKey, data);

                return {
                    ...data,
                    ...primaryKeyData,
                };
            }),
        );

        const url = `${apiUrl}/${resource}?${query}`;

        return httpClient(url, {
            method: 'PATCH',
            headers: new Headers({
                Prefer: 'return=representation',
                'Content-Type': 'application/json',
            }),
            user: await getUserOption(),
            body,
        }).then(({ json }) => ({
            data: json.map((data: any) => encodeId(data, primaryKey)),
        }));
    },

    create: async (resource, params) => {
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const url = `${apiUrl}/${resource}`;

        return httpClient(url, {
            method: 'POST',
            headers: new Headers({
                Accept: 'application/vnd.pgrst.object+json',
                Prefer: 'return=representation',
                'Content-Type': 'application/json',
            }),
            user: await getUserOption(),
            body: JSON.stringify(params.data),
        }).then(
            ({ json }) => {
                const result : CreateResult = {
                    data: {
                        ...params.data,
                        id: encodeId(json, primaryKey),
                    },
                };
                return result;
            },
        )
            .catch((error: HttpError) => manageBackendValidationErrors(error));
    },

    delete: async (resource, params) => {
        const { id } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const query = getQuery(primaryKey, id, resource);

        const url = `${apiUrl}/${resource}?${query}`;

        return httpClient(url, {
            method: 'DELETE',
            headers: new Headers({
                Accept: 'application/vnd.pgrst.object+json',
                Prefer: 'return=representation',
                'Content-Type': 'application/json',
            }),
            user: await getUserOption(),
        }).then(({ json }) => ({ data: dataWithId(json, primaryKey) }));
    },

    deleteMany: async (resource, params) => {
        const { ids } = params;
        const primaryKey = getPrimaryKey(resource, primaryKeys);

        const query = getQuery(primaryKey, ids, resource);

        const url = `${apiUrl}/${resource}?${query}`;

        return httpClient(url, {
            method: 'DELETE',
            headers: new Headers({
                Prefer: 'return=representation',
                'Content-Type': 'application/json',
            }),
            user: await getUserOption(),
        }).then(({ json }) => ({
            data: json.map((data: any) => encodeId(data, primaryKey)),
        }));
    },
});
