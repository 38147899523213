import React, { ChangeEvent, ReactElement } from 'react';
import * as MUI from '@mui/material';

interface TabPanelTypes {
    index: number;
    value: number;
    children: ReactElement[];
}

interface TileTabsTypes {
    tabs: string[];
    icons: ReactElement[];
    children: ReactElement[];
}

const TabPanel = ({
    index,
    value,
    children,
}: TabPanelTypes) => (value === index
    ? <>{children}</>
    : null);

const TileTabs: React.FC<TileTabsTypes> = ({
    children,
    icons,
    tabs,
}) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <MUI.Box>
            <MUI.Paper variant="outlined">
                <MUI.Tabs onChange={handleChange} value={value} textColor="primary" variant="fullWidth">
                    {/*  Content and Settings tabs */}
                    {tabs.map((tab, index) => (
                        <MUI.Tab key={`tab-${index}`} label={tab} icon={icons[index]} />
                    ))}
                </MUI.Tabs>
            </MUI.Paper>
            {/* Content and Settings panels */}
            {children.map((tab: any, index: number) => (
                <TabPanel key={`panel-${index}`} index={index} value={value}>
                    {tab}
                </TabPanel>
            ))}
        </MUI.Box>
    );
};

export default TileTabs;
